import { syncCacheGet } from "./cache";
import { DOMParser as XMLDOMParser } from "@xmldom/xmldom";
import { gpx } from "@mapbox/togeojson";
import { Asset } from "contentful";

export function gpxToGeoJSON(
  cacheKey: string,
  xmlString: string
): GeoJSON.Geometry {
  return syncCacheGet(`geojson.${cacheKey}`, () => {
    const dom = new XMLDOMParser().parseFromString(
      xmlString,
      "text/xml"
    ) as unknown as Document; // This is how the author of this library is doing it https://github.com/placemark/togeojson/blob/a27fa7dcadba1b11fed92e58cec35ae9f8c146ad/lib/index.test.ts#L10

    const geojson = gpx(dom);

    if (geojson.features.every((f) => f.geometry.type === "MultiLineString")) {
      const features = geojson.features as Array<
        GeoJSON.Feature<GeoJSON.MultiLineString>
      >;

      return {
        type: "MultiLineString",
        coordinates: features.flatMap(
          (feature) => feature.geometry.coordinates
        ),
      };
    }

    return geojson.features[0].geometry;
  });
}

export function assetToGeoJSON(asset: Asset, data: string): GeoJSON.Geometry {
  if (asset.fields.file.contentType === "application/json" || data[0] === "{") {
    return JSON.parse(data);
  }

  if (
    asset.fields.file.contentType === "application/xml" ||
    data.startsWith("<?xml")
  ) {
    return gpxToGeoJSON(asset.sys.id, data);
  }

  throw new Error(
    `Failed to transform asset ${asset.fields.title} with type ${asset.fields.file.contentType} to GeoJSON`
  );
}
