import { createStore } from "./storage";

interface CacheOptions {
  disable?: boolean;
}

export function isCachingEnabled() {
  return localStorage.getItem("disableCache") === null;
}

const cacheStore = createStore("cache");

export function cacheGet<T, S = () => T | Promise<T>>(
  key: string,
  supplier: S
): Promise<S extends () => Promise<infer R> ? R : T>;
export function cacheGet<T, S = () => T | Promise<T>>(
  key: string,
  options: CacheOptions,
  supplier: S
): Promise<S extends () => Promise<infer R> ? R : T>;
export async function cacheGet<T>(
  key: string,
  supplierOrOptions: any,
  supplierOrUndef?: any
): Promise<T> {
  const supplier: () => T | Promise<T> =
    supplierOrUndef === undefined ? supplierOrOptions : supplierOrUndef;
  const options: CacheOptions =
    supplierOrUndef === undefined ? {} : supplierOrOptions;

  const disabled = !isCachingEnabled() || options.disable;

  if (!disabled) {
    const cachedValue = await cacheStore.getItem<string>(key);

    if (cachedValue !== null) {
      console.debug("cache-hit", key);
      return JSON.parse(cachedValue);
    }
  }

  console.debug("cache-miss", key);
  const result = supplier();

  if (result instanceof Promise) {
    return result.then(async (data: T) => {
      if (!disabled) {
        await cacheStore.setItem<string>(key, JSON.stringify(data));
      }
      return data;
    });
  }

  if (!disabled) {
    await cacheStore.setItem(key, JSON.stringify(result));
  }

  return result;
}

// @deprecated
export function syncCacheGet<T>(key: string, supplier: () => T): T;
// @deprecated
export function syncCacheGet<T>(
  key: string,
  options: CacheOptions,
  supplier: () => T
): T;
// @deprecated
export function syncCacheGet<T>(
  key: string,
  supplierOrOptions: any,
  supplierOrUndef?: any
): T {
  const supplier: () => T =
    supplierOrUndef === undefined ? supplierOrOptions : supplierOrUndef;
  const options: CacheOptions =
    supplierOrUndef === undefined ? {} : supplierOrOptions;

  const disabled = !isCachingEnabled() || options.disable;

  if (!disabled) {
    const cachedValue = localStorage.getItem(key);

    if (cachedValue !== null) {
      // console.debug("cache-hit", key);
      return JSON.parse(cachedValue);
    }
  }

  // console.debug("cache-miss", key);
  const result = supplier();

  if (!disabled) {
    try {
      localStorage.setItem(key, JSON.stringify(result));
    } catch (e) {
      if (e.name !== "QuotaLimitExceeded") {
        throw e;
      }
    }
  }

  return result;
}
