import { Memoize } from "typescript-memoize";
import {
  AccommodationModel,
  ActivityModel,
  CountryVisitModel,
  DestinationModel,
  DestinationVisitModel,
  JournalEntryModel,
  RegionVisitModel,
  TourModel,
} from "../../model/v1";
import { CONTENT_TYPE_JOURNAL_ENTRY, ContentfulData } from "../v1";
import { ContentfulJournalEntry } from "./journal";

interface DestinationTreeNode {
  type: "destination" | "region" | "country";
  name: string;
  arrivalDate: Date;
  departureDate: Date;
}

interface DestinationNode extends DestinationTreeNode {
  type: "destination";
  parent: CountryNode | RegionNode;
  accommodations: AccommodationModel[];
}

interface RegionNode extends DestinationTreeNode {
  type: "region";
  parent: CountryNode;
  destinations: DestinationNode[];
}

interface CountryNode extends DestinationTreeNode {
  type: "country";
  regions: RegionNode[];
  destinations: DestinationNode[];
}

class DestinationTree {
  countries: CountryNode[];
}

function createTree(accoms: AccommodationModel[]): DestinationTree {
  const tree: DestinationTree = {
    countries: [],
  };

  let currentAccom: AccommodationModel;
  let previousAccom: AccommodationModel | null = null;
  let previousDestination: DestinationModel | null = null;
  let currentCountryNode: CountryNode;
  let currentRegionNode: RegionNode | null = null;
  let currentDestinationNode: DestinationNode;
  let previousDestinationNode: DestinationNode | null = null;

  const multiStayStack = [];
  const accomStack = [...accoms];
  accomStack.reverse();

  while (accomStack.length > 0) {
    let currentArrivalDate: Date;
    let previousDepartureDate: Date | null = null;

    previousAccom = currentAccom;

    if (multiStayStack.length > 0) {
      currentAccom = multiStayStack.pop();
      // We're arriving back in a destination we've already visited
      // We assume you arrived back in this destination on the same day you left the previoous
      currentArrivalDate = previousAccom.getCheckOutDate();
      previousDepartureDate = previousAccom.getCheckOutDate();
    } else {
      currentAccom = accomStack.pop();
      currentArrivalDate = currentAccom.getCheckInDate();

      if (previousAccom) {
        if (currentAccom.getCheckInDate() < previousAccom.getCheckOutDate()) {
          // This means you must be staying in 2 places at once - using previousAccom as a base and staying temporarily
          // in currentAccom.
          multiStayStack.push(previousAccom);
          previousDepartureDate = currentAccom.getCheckInDate();
        } else {
          previousDepartureDate = previousAccom.getCheckOutDate();
        }
      }
    }

    const destination = currentAccom.getDestination();

    if (
      !previousDestination ||
      destination.getCountry() !== previousDestination.getCountry()
    ) {
      if (currentCountryNode) {
        currentCountryNode.departureDate = previousDepartureDate;
        if (currentRegionNode) {
          currentRegionNode.departureDate = previousDepartureDate;
        }
      }

      currentCountryNode = {
        type: "country",
        name: destination.getCountry(),
        arrivalDate: currentArrivalDate,
        departureDate: new Date(),
        regions: [],
        destinations: [],
      };
      tree.countries.push(currentCountryNode);
    }

    if (
      !previousDestination ||
      previousDestination.getName() !== destination.getName()
    ) {
      previousDestinationNode = currentDestinationNode;

      currentDestinationNode = {
        type: "destination",
        // For now we assume the destination parent is a country, but it could be a region.
        // We handle that in the region branch below
        parent: currentCountryNode,
        name: destination.getName(),
        arrivalDate: currentArrivalDate,
        departureDate: new Date(),
        accommodations: [currentAccom],
      };

      // We're in a new destination, so we update the departure date of the previous
      if (previousDestinationNode) {
        previousDestinationNode.departureDate = previousDepartureDate;
      }

      if (destination.getRegion()) {
        if (
          !previousDestination ||
          previousDestination.getRegion() !== destination.getRegion()
        ) {
          // This destination specifices a region and it's different from the previous

          // We must have a left the previous region, if there is one
          if (currentRegionNode) {
            currentRegionNode.departureDate = previousDepartureDate;
          }

          currentRegionNode = {
            type: "region",
            parent: currentCountryNode,
            name: destination.getRegion(),
            arrivalDate: currentArrivalDate,
            departureDate: new Date(),
            destinations: [currentDestinationNode],
          };

          currentDestinationNode.parent = currentRegionNode;

          currentCountryNode.regions.push(currentRegionNode);
        } else {
          // This destination specifices a region and it's the same as the previous
          currentRegionNode.destinations.push(currentDestinationNode);
        }
      } else {
        // This destination doesn't specify a region

        // If the previous destination specified a region, we've left it now
        if (currentRegionNode) {
          currentRegionNode.departureDate = previousDepartureDate;
          currentRegionNode = null;
        }

        currentCountryNode.destinations.push(currentDestinationNode);
      }
    } else {
      // We're in the same destination as the previous accommodation
      currentDestinationNode.accommodations.push(currentAccom);
    }

    previousDestination = destination;
  }

  console.log(tree);
  return tree;
}

export class ContentfulTour implements TourModel {
  tree: DestinationTree;

  constructor(
    private accoms: AccommodationModel[],
    private data: ContentfulData
  ) {
    this.tree = createTree(accoms);
  }

  @Memoize()
  getCountryVisits(): CountryVisitModel[] {
    return this.tree.countries.map(
      (node) => new ContentfulCountryVisit(node, this.data)
    );
  }
}

export class ContentfulCountryVisit implements CountryVisitModel {
  constructor(private node: CountryNode, private data: ContentfulData) {}

  public type: "country" = "country";

  getName(): string {
    return this.node.name.trim();
  }
  getArrivalDate(): Date {
    return this.node.arrivalDate;
  }

  getDepartureDate(): Date {
    throw new Error("Method not implemented.");
  }

  @Memoize()
  getRegionVisits(): RegionVisitModel[] {
    return this.node.regions.map(
      (node) => new ContentfulRegionVisit(node, this.data)
    );
  }

  @Memoize()
  getDestinationVisits(): DestinationVisitModel[] {
    return this.node.destinations.map(
      (node) => new ContentfulDestinationVisit(node, this.data)
    );
  }

  @Memoize()
  getAccommodations(): AccommodationModel[] {
    const accomModels = this.node.regions
      .flatMap((region) =>
        region.destinations.flatMap((destination) => destination.accommodations)
      )
      .concat(
        this.node.destinations.flatMap(
          (destination) => destination.accommodations
        )
      );

    accomModels.sort(
      (a, b) => a.getCheckInDate().getTime() - b.getCheckInDate().getTime()
    );
    return accomModels;
  }

  @Memoize()
  getActivities(): ActivityModel[] {
    return this.getDestinationVisits().flatMap((visit) =>
      visit.getActivities()
    );
  }
}

export class ContentfulRegionVisit implements RegionVisitModel {
  constructor(private node: RegionNode, private data: ContentfulData) {}

  public type: "region" = "region";

  @Memoize()
  getParentVisit(): CountryVisitModel {
    return new ContentfulCountryVisit(this.node.parent, this.data);
  }

  getName(): string {
    return this.node.name;
  }
  getArrivalDate(): Date {
    return this.node.arrivalDate;
  }
  getDepartureDate(): Date {
    throw new Error("Method not implemented.");
  }

  @Memoize()
  getDestinationVisits(): DestinationVisitModel[] {
    return this.node.destinations.map(
      (node) => new ContentfulDestinationVisit(node, this.data)
    );
  }

  @Memoize()
  getAccommodations(): AccommodationModel[] {
    const accomModels = this.node.destinations.flatMap(
      (destination) => destination.accommodations
    );

    accomModels.sort(
      (a, b) => a.getCheckInDate().getTime() - b.getCheckInDate().getTime()
    );
    return accomModels;
  }

  getActivities(): ActivityModel[] {
    return this.getDestinationVisits().flatMap((visit) =>
      visit.getActivities()
    );
  }
}

export class ContentfulDestinationVisit implements DestinationVisitModel {
  constructor(private node: DestinationNode, private data: ContentfulData) {}

  public type: "destination" = "destination";

  @Memoize()
  getParentVisit(): CountryVisitModel | RegionVisitModel {
    return this.node.parent.type === "country"
      ? new ContentfulCountryVisit(this.node.parent, this.data)
      : new ContentfulRegionVisit(this.node.parent, this.data);
  }

  getName(): string {
    return this.node.name;
  }
  getArrivalDate(): Date {
    return this.node.arrivalDate;
  }
  getDepartureDate(): Date {
    return this.node.departureDate;
  }

  getDestination(): DestinationModel {
    throw new Error("Method not implemented.");
  }

  @Memoize()
  getAccommodations(): AccommodationModel[] {
    const accomModels = [...this.node.accommodations];
    accomModels.sort(
      (a, b) => a.getCheckInDate().getTime() - b.getCheckInDate().getTime()
    );
    return accomModels;
  }

  getActivities(): ActivityModel[] {
    return this.getJournalEntries().flatMap((entry) => entry.getActivities());
  }

  @Memoize()
  getJournalEntries(): JournalEntryModel[] {
    const journalEntries = Object.values(
      this.data.linkedEntries[CONTENT_TYPE_JOURNAL_ENTRY]
    ).filter(
      (entry) =>
        new Date(entry.fields.date) >= this.node.arrivalDate &&
        new Date(entry.fields.date) < this.node.departureDate
    );
    const journalEntryModels = journalEntries.map(
      (entry) => new ContentfulJournalEntry(entry, this.data)
    );
    journalEntryModels.sort(
      (a, b) => a.getDate().getTime() - b.getDate().getTime()
    );
    return journalEntryModels;
  }
}
